:root {
    --navBg: #060b26;
  }
  
  .navbar {
    background-color: var(--navBg);
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .nav-item-expanded a{
    color: #213360;
  }

  .nav-item-expanded a:hover{
    color: #21CDC0;
  }
  .nav-menu {
    background-color: var(--navBg);
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 200000;

  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
    z-index: 200000;
  }
  
  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0 0px 16px;
    list-style: none;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
  }
  
  .nav-text a:hover {
    color:#21CDC0 ;
  }
  
  .nav-menu-items {
    width: 100%;
    padding: 0;
  }
  
  .navbar-toggle {
    background-color: var(--navBg);
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  span {
    margin-left: 16px;
  }
  