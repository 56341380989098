.header-topbar-custom {
  min-height: 50px;
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #283b6a;
  padding-inline: 10px;
  padding-block: 5px;
}
.header-topbar-custom .contact__list li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13px;
  margin-right: 20px;
}
.header-topbar-custom .contact__list li a:not(.btn) {
  color: #f4f4f4;
}
.header-topbar-custom .contact__list li a:not(.btn):hover {
  color: #fff;
}
.header-topbar-custom .contact__list li > i {
  font-size: 16px;
  color: #788fc8;
  margin-right: 8px;
}
.header-topbar-custom .contact__list li:last-child {
  margin-right: 0;
}
.header-topbar-custom .social-icons li a {
  color: #213360;
  background-color: #21cdc0;
}
.header-topbar-custom .social-icons li a:hover {
  background-color: #fff;
}
.header-topbar-custom .header-topbar-custom__search {
  position: relative;
}
.header-topbar-custom .header-topbar-custom__search .form-control {
  height: 30px;
  border: 0;
  border-radius: 0;
  color: #fff;
  background-color: transparent;
  border: 2px solid;
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.15);
}
.header-topbar-custom .header-topbar-custom__search .form-control:focus {
  border-color: rgba(255, 255, 255, 0.15) !important;
}
.header-topbar-custom
  .header-topbar-custom__search
  .form-control::-webkit-input-placeholder {
  color: #788fc8;
  font-style: italic;
  font-size: 13px;
}
.header-topbar-custom
  .header-topbar-custom__search
  .form-control:-moz-placeholder {
  color: #788fc8;
  font-style: italic;
  font-size: 13px;
}
.header-topbar-custom
  .header-topbar-custom__search
  .form-control::-moz-placeholder {
  color: #788fc8;
  font-style: italic;
  font-size: 13px;
}
.header-topbar-custom
  .header-topbar-custom__search
  .form-control:-ms-input-placeholder {
  color: #788fc8;
  font-style: italic;
  font-size: 13px;
}
.header-topbar-custom
  .header-topbar-custom__search
  .header-topbar-custom__search-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
}
.header-topbar-custom .lang-dropdown .lang-dropdown-toggle {
  padding: 0 10px;
}
.header-topbar-custom .lang-dropdown .lang-dropdown-toggle img {
  margin-right: 7px;
}
.header-topbar-custom .action__btn {
  color: #fff !important;
}
.header-layout1,
.header-layout2 {
  -webkit-box-shadow: 0 5px 83px 0 rgba(9, 29, 62, 0.1);
  box-shadow: 0 5px 83px 0 rgba(9, 29, 62, 0.1);
}
.header-layout2 .header-topbar-custom > .container-fluid > .row,
.header-layout3 .header-topbar-custom > .container-fluid.row {
  height: 60px;
}
.is-sticky {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 1040;
  border-bottom: 0 !important;
  height: 80px !important;
  max-height: 80px !important;
  background-color: #fff !important;
  -webkit-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.07);
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.07);
  -webkit-animation: translateHeader 0.8s;
  animation: translateHeader 0.8s;
}
